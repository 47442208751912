import React, { Component } from 'react'

export class WebSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            fill="none"
    
        >
            <path
            fill="#F90"
            d="M32.5 13.75h-25a2.5 2.5 0 0 0-2.5 2.5v15a2.5 2.5 0 0 0 2.5 2.5h25a2.5 2.5 0 0 0 2.5-2.5v-15a2.5 2.5 0 0 0-2.5-2.5Zm0 17.5h-25v-15h25v15ZM7.5 10a1.25 1.25 0 0 1 1.25-1.25h22.5a1.25 1.25 0 0 1 0 2.5H8.75A1.25 1.25 0 0 1 7.5 10ZM10 5a1.25 1.25 0 0 1 1.25-1.25h17.5a1.25 1.25 0 0 1 0 2.5h-17.5A1.25 1.25 0 0 1 10 5Z"
            />
        </svg>
    )
  }
}

export default WebSvg