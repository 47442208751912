import React, { Component } from 'react'
import StarSvg from './Svg/StarSvg'

export class Card1 extends Component {
  render() {
    const{title, svg, text, title_width, resp_width} = this.props;
    return (
      <div className='flex flex-col gap-[32px] p-[32px] bg-[#fff] rounded-[8px] '>
        <div className={`flex items-center gap-[16px]`}>
            {svg}
            <h3 className={` ${title_width} ${resp_width} font-[800] text-[20px] leading-[28px] text-[#4978A4] max-[450px]:text-[18px] `}>
                {title}
            </h3>
        </div>

        <div className={` max-w-[436px]`}>
            <span className={` text-[16px] text-[#000] font-[500] leading-[24px] `}>
                {text}
            </span>
        </div>
        

      </div>
    )
  }
}

export default Card1