import React, { Component } from 'react'
import MainPage from './components/MainPage'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

export class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    )
  }
}

export default App