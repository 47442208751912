import React, { Component } from 'react'

export class StarSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        fill="none"
      >
        <path
          fill="#F90"
          d="M37.372 15.197a2.56 2.56 0 0 0-2.228-1.76l-9.219-.743-3.56-8.608a2.556 2.556 0 0 0-4.73 0l-3.555 8.606-9.224.745a2.572 2.572 0 0 0-1.464 4.51l7.031 6.067-2.142 9.072a2.56 2.56 0 0 0 3.828 2.784L20 31.014l7.895 4.856a2.562 2.562 0 0 0 3.824-2.784l-2.15-9.073 7.031-6.068a2.567 2.567 0 0 0 .772-2.748Zm-2.397.855-7.61 6.562a1.25 1.25 0 0 0-.4 1.236l2.326 9.813a.058.058 0 0 1-.027.075c-.028.021-.036.017-.06 0l-8.55-5.258a1.25 1.25 0 0 0-1.309 0l-8.55 5.26c-.023.015-.03.02-.06 0a.057.057 0 0 1-.026-.074l2.325-9.813a1.25 1.25 0 0 0-.4-1.236l-7.609-6.562c-.019-.016-.036-.03-.02-.078.015-.049.028-.043.051-.046l9.988-.806a1.25 1.25 0 0 0 1.05-.772L19.94 5.04c.012-.026.017-.039.054-.039.038 0 .043.013.055.04l3.856 9.313a1.251 1.251 0 0 0 1.055.769l9.987.806c.024 0 .038 0 .052.045.014.046 0 .063-.025.079Z"
        />
      </svg>
    )
  }
}

export default StarSvg