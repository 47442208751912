import React, { Component } from 'react'
import vector from './Images/Vector.png'
import FacebookSvg from './Svg/FacebookSvg'
import InstagramSvg from './Svg/InstagramSvg'
import WhatsappSvg from './Svg/WhatsappSvg'

export class Footer extends Component {
  render() {
    return (
      <div className='bg-[#0084FF] mt-[128px] p-[72px_0px] max-[800px]:mt-[64px] max-[500px]:mt-[32px]' id='footer'>
        <div className='container flex justify-between items-center max-[700px]:flex-col max-[700px]:gap-[32px]'>
          <img src={vector} className='w-[120px] h-[56px]' />
          <div className='flex items-center gap-[16px] max-[450px]:flex-col'>
            <span className='font-[500] text-[16px] leading-[20px] text-[#fff]'>
              Əlaqə üçün
            </span>

            <div className='flex items-center gap-[16px] max-[400px]:flex-col max-[400px]:gap-[20px]'>
              <div className='flex items-center gap-[16px]'>
                <a href='https://www.facebook.com/despro.az' target='_blank'>
                  <FacebookSvg />
                </a>
                <a href='https://www.instagram.com/despro.az/' target='_blank'>
                  <InstagramSvg />
                </a>
                <a href='https://wa.me/+994556004009' target='_blank'>
                  <WhatsappSvg />
                </a>
              </div>

              <a href='tel:+994556004009'>
                <span className='font-[500] text-[16px] leading-[20px] text-[#fff]'>+994 55 600 4009</span>
              </a>
            </div>



          </div>
        </div>
      </div>
    )
  }
}

export default Footer