import React, { Component } from 'react'
import Card1 from './Card1'
import StarSvg from './Svg/StarSvg'
import FlagSvg from './Svg/FlagSvg'
import BookSvg from './Svg/BookSvg'
import WebSvg from './Svg/WebSvg'

export class CardSection extends Component {
  render() {
    return (
        <div className=' w-full container  pb-[64px] max-[880px]:gap-[32px] '>
            <div className='grid grid-cols-2 gap-[32px] max-[900px]:grid-cols-1 '>
                <Card1 
                    svg={<StarSvg />}
                    title='Loqo və korporativ
                    üslubun tərtibatı'
                    title_width='max-w-[201px]'
                    resp_width='w-[70%]'
                    text='Şirkətlərin kimliyini təmsil edən əsas elementlər baxmayaraq ki, bu iki konsept fərqli məqsədlərə xidmət edir, hər ikisi də şirkətin görünüşünü, təşkilatını və təmsilini formalaşdırmağa kömək edir.'
                />

                <Card1 
                    svg={<FlagSvg />}
                    title='Daxili və çöl reklamlarının
                    hazırlanması'
                    title_width='max-w-[278px] '
                    resp_width='w-[80%]'
                    text='Şirkətinizi təmsil edən və müştərilərinizə təqdim etdiyiniz materiallar. Bu məhsullar, şirkətinizin görünüşünü formalaşdırmağa və brendinizi tanıtmağa kömək edir.'
                />
      
                <Card1 
                    svg={<BookSvg />}
                    title='Çap məhsullarının
                    hazırlanması'
                    title_width='max-w-[201px]'
                    resp_width='w-[80%]'
                    text='Müxtəlif növ dizayn və çap proseslərini əhatə edir. Bu, vizit kartları, flayerlər, broşurlar, afişalar, jurnallar, kitablar və digər çeşidli çap materiallarının hazırlanması.'
                />

                <Card1 
                    svg={<WebSvg />}
                    title='Sayt və mobil
                    tədbiqin hazırlanması'
                    title_width='max-w-[238px]'
                    resp_width='w-[80%]'
                    text='Sayt və mobil tətbiqin hazırlanması, müasir biznesin vacib hissəsidir. Bu proseslər, şirkətlərin və brendlərin onlayn və mobil platformalarda təmsil olunmasını təmin edir.'
                />
            </div>

        </div>
      
    )
  }
}

export default CardSection