import React, { Component } from 'react'
import Nav from './Header/Nav'
import CardSection from './CardSection'
import ProjectsSection from './ProjectsSection'
import Animation from './Animation'
import Footer from './Footer'

export class MainPage extends Component {
  render() {
    return (
      <div>
        <Nav />
        <div className='flex flex-col gap-[32px]'>
          <Animation />
          <CardSection />

          <ProjectsSection />

          <Footer />
        </div>
      </div>
      
    )
  }
}

export default MainPage