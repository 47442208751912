import React, { Component } from 'react'

export class BookSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
  >
    <path
      fill="#F90"
      d="M36.195 30.398 31.01 5.742a2.5 2.5 0 0 0-2.968-1.936l-7.314 1.572a2.513 2.513 0 0 0-1.922 2.969l5.186 24.656a2.5 2.5 0 0 0 2.968 1.94l7.314-1.571a2.514 2.514 0 0 0 1.922-2.974ZM21.25 7.836v-.014l7.313-1.563.52 2.48-7.313 1.573-.52-2.476Zm1.034 4.917 7.316-1.57.522 2.484-7.31 1.572-.528-2.486Zm1.038 4.933 7.316-1.572 2.078 9.881-7.316 1.572-2.078-9.881ZM33.75 30.928l-7.313 1.563-.52-2.48 7.313-1.573.52 2.476v.014ZM16.25 5h-7.5a2.5 2.5 0 0 0-2.5 2.5v25a2.5 2.5 0 0 0 2.5 2.5h7.5a2.5 2.5 0 0 0 2.5-2.5v-25a2.5 2.5 0 0 0-2.5-2.5Zm-7.5 2.5h7.5V10h-7.5V7.5Zm0 5h7.5v15h-7.5v-15Zm7.5 20h-7.5V30h7.5v2.5Z"
    />
  </svg>
    )
  }
}

export default BookSvg