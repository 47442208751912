import React, { Component } from 'react'

export class FlagSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
  
  >
    <path
      fill="#F90"
      d="M6.681 7.813a1.25 1.25 0 0 0-.431.937V35a1.25 1.25 0 0 0 2.5 0v-6.91c4.186-3.307 7.792-1.524 11.945.532 2.563 1.267 5.322 2.633 8.282 2.633 2.176 0 4.459-.742 6.846-2.813a1.25 1.25 0 0 0 .432-.937V8.75a1.25 1.25 0 0 0-2.074-.938c-4.375 3.786-8.081 1.952-12.376-.175-4.45-2.206-9.496-4.701-15.124.176ZM33.75 26.913c-4.186 3.306-7.792 1.522-11.945-.533-3.907-1.93-8.252-4.083-13.055-1.312V9.342c4.186-3.306 7.792-1.523 11.945.531 3.907 1.93 8.253 4.083 13.055 1.313v15.728Z"
    />
    </svg>
    )
  }
}

export default FlagSvg