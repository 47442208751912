import React, { Component } from 'react'

export class WhatsappSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
    
      >
        <path
          fill="#fff"
          d="m23.45 18.105-4-2a1 1 0 0 0-1 .063l-1.836 1.224a5.07 5.07 0 0 1-2-2l1.225-1.836a1 1 0 0 0 .063-1l-2-4a1 1 0 0 0-.9-.556 5 5 0 0 0-5 5 11.012 11.012 0 0 0 11 11 5 5 0 0 0 5-5 1 1 0 0 0-.552-.895ZM19.003 22a9.01 9.01 0 0 1-9-9 3 3 0 0 1 2.411-2.943l1.435 2.875-1.221 1.818a1 1 0 0 0-.091.939 7.06 7.06 0 0 0 3.768 3.768 1 1 0 0 0 .948-.082l1.826-1.218 2.875 1.436A3 3 0 0 1 19.003 22Zm-3-19A13 13 0 0 0 4.525 22.11l-1.418 4.256a2 2 0 0 0 2.53 2.53l4.256-1.419A13 13 0 1 0 16.003 3Zm0 24a10.984 10.984 0 0 1-5.508-1.476 1 1 0 0 0-.817-.084L5.003 27l1.559-4.675a1 1 0 0 0-.083-.817A11 11 0 1 1 16.003 27Z"
        />
      </svg>
    )
  }
}

export default WhatsappSvg