import React, { Component } from 'react'

export class FacebookSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
        
      >
        <path
          fill="#fff"
          d="M16 3a13 13 0 1 0 13 13A13.014 13.014 0 0 0 16 3Zm1 23.954V19h3a1 1 0 0 0 0-2h-3v-3a2 2 0 0 1 2-2h2a1 1 0 0 0 0-2h-2a4 4 0 0 0-4 4v3h-3a1 1 0 0 0 0 2h3v7.954a11 11 0 1 1 2 0Z"
        />
      </svg>
    )
  }
}

export default FacebookSvg